























































import Vue from 'vue';

// eslint-disable-next-line no-shadow
enum FunctionSwitch {
  Button,
  Input,
  Checkbox,
}

export default Vue.extend({
  name: 'ItemCounter',
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    maxAmount: {
      type: [Number, Boolean],
      default: () => false,
    },
  },
  data() {
    return {
      lastAmount: 0,
    };
  },
  computed: {
    disable(): boolean {
      return this.amount === this.maxAmount;
    },
    getEnum() {
      return FunctionSwitch;
    },
  },
  methods: {
    changeAmount(fn: FunctionSwitch, event: boolean | string | null) {
      const item = {
        amount: this.amount,
        id: this.id,
        title: this.title,
        maxAmount: this.maxAmount,
      };
      switch (fn) {
        case FunctionSwitch.Button:
          if (typeof event === 'boolean') {
            item.amount = this.updateByClick(event);
          }
          break;
        case FunctionSwitch.Input:
          if (typeof event === 'string') {
            item.amount = this.updateByInput(event);
          }
          break;
        case FunctionSwitch.Checkbox:
          if (typeof event === 'boolean' || typeof event === 'object') {
            item.amount = this.updateByCheckbox(event);
          }
          break;
        default:
          break;
      }
      this.$emit('updateItem', item);
      this.$emit('chamgeObserver', true);
    },

    updateByClick(add: boolean): number {
      return add ? this.amount + 1 : this.amount - 1;
    },
    updateByInput(event: string): number {
      return parseInt(event, 10) || 0;
    },
    updateByCheckbox(event: boolean | null): number {
      if (typeof this.maxAmount === 'number') {
        return event ? this.maxAmount : this.lastAmount;
      }
      return 0;
    },
  },
  watch: {
    amount: {
      handler(newAmount: number, oldAmount: number) {
        this.lastAmount = oldAmount;
      },
    },
  },
  created() {
    this.lastAmount = 0;
  },
});
